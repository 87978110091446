import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ArtistStep from '@/components/onboarding/ArtistStep';
import ChooseTrack from '@/components/onboarding/ChooseTrack';
import CreateAccountPage from '@/components/onboarding/CreateAccount';
import LoginOnboardingPage from '@/components/onboarding/LoginOnboarding';
import OnboardingLandingPage from '@/components/onboarding/OnboardingLandingPage';
import ReleaseDatePage from '@/components/onboarding/ReleaseDate';
import ReleaseDetailsPage from '@/components/onboarding/ReleaseDetails';
import UserStep from '@/components/onboarding/UserStep';
import { MotionElement } from '@/components/utility/framer-motion-wrappers/MotionElement';
import { MotionWrapper } from '@/components/utility/framer-motion-wrappers/MotionWrapper';
import { PromoteFlowStepFormatter } from '@/formatters/PromoteFlowStepFormatter';
import useAccountContext from '@/hooks/context/useAccountContext';
import useOnboardingContext from '@/hooks/context/useOnboardingContext';
import { OnboardingSteps } from '@/models/Enums';
import { OnboardingForm } from '@/models/Onboarding';
import UserTracking from '@/services/UserTracking';

const OnboardingPage = ({ postSignUp, isLogin }: { postSignUp?: boolean; isLogin?: boolean }) => {
  const { account } = useAccountContext();
  const navigate = useNavigate();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [hasRunInitialLoad, setHasRunInitialLoad] = useState(false);

  const { onboardingStep, setOnboardingStep, onboardingDirection } = useOnboardingContext();
  const defaultValues = {
    email: '',
    firstName: '',
    lastName: '',
    country: '',
    marketing: false,
    artistId: '',
    releaseChoice: 0,
    releaseDetails: {
      releaseTitle: '',
      releaseSpotifyId: '',
      releaseDate: '',
      releaseType: '',
      releaseArtwork: '',
    },
  };
  const formMethods = useForm<OnboardingForm>({ defaultValues });

  const hasRunLoadFormRef = useRef(false);

  const loadFormAndCreateAccount = useCallback(() => {
    if (hasRunInitialLoad || hasRunLoadFormRef.current) return;

    hasRunLoadFormRef.current = true;
    const storedForm = localStorage.getItem('onboardingForm');

    if (storedForm && storedForm !== 'undefined') {
      const formDetails = JSON.parse(storedForm);
      formMethods.reset(formDetails);
      setHasRunInitialLoad(true);
      setOnboardingStep(OnboardingSteps.CREATE_ACCOUNT);
    } else {
      setHasRunInitialLoad(true);
      setOnboardingStep(OnboardingSteps.USER);
    }
  }, [formMethods, hasRunInitialLoad, setOnboardingStep]);

  useEffect(() => {
    if (isLogin) {
      return setOnboardingStep(OnboardingSteps.LOGIN);
    }
    if (account && account.artistId) {
      return navigate('/');
    }

    if (account && !account.artistId) {
      return setOnboardingStep(OnboardingSteps.ARTIST);
    }
  }, [account, formMethods, isLogin, loadFormAndCreateAccount, navigate, setOnboardingStep]);

  useEffect(() => {
    if (!hasRunInitialLoad && postSignUp) {
      loadFormAndCreateAccount();
    }
  }, [hasRunInitialLoad, loadFormAndCreateAccount, postSignUp]);

  const stepRef = useRef(onboardingStep);

  const trackProductViewed = useCallback(() => {
    if (stepRef.current !== onboardingStep) {
      UserTracking?.onboardingProductViewed?.({
        product: 'Onboarding',
        productScreenName: PromoteFlowStepFormatter('onboarding', onboardingStep),
        ...(onboardingStep > OnboardingSteps.CHOOSE_TRACK && {
          promoteChoice: PromoteFlowStepFormatter('promote-choice', formMethods.getValues().releaseChoice),
        }),
      });
    } else if (isInitialLoad) {
      UserTracking?.onboardingProductViewed?.({
        product: 'Onboarding',
        productScreenName: PromoteFlowStepFormatter('onboarding', onboardingStep),
      });
      setIsInitialLoad(false);
    }
  }, [formMethods, isInitialLoad, onboardingStep]);

  useEffect(() => {
    trackProductViewed();
  }, [trackProductViewed]);

  return (
    <div data-testid="onboarding-page" className="w100p text-center min-h100vh">
      <FormProvider {...formMethods}>
        <MotionWrapper isNext={onboardingDirection === 'next'} key={onboardingStep}>
          {onboardingStep === OnboardingSteps.PRE_SCREEN && (
            <MotionElement isNext={onboardingDirection === 'next'}>
              <OnboardingLandingPage />
            </MotionElement>
          )}
          {onboardingStep === OnboardingSteps.LOGIN && (
            <MotionElement isNext={onboardingDirection === 'next'}>
              <LoginOnboardingPage />
            </MotionElement>
          )}
          {onboardingStep === OnboardingSteps.SIGN_UP && (
            <MotionElement isNext={onboardingDirection === 'next'}>
              <LoginOnboardingPage isSignUp={true} formDetails={formMethods.getValues()} />
            </MotionElement>
          )}
          {onboardingStep === OnboardingSteps.USER && (
            <MotionElement isNext={onboardingDirection === 'next'}>
              <UserStep />
            </MotionElement>
          )}
          {onboardingStep === OnboardingSteps.ARTIST && (
            <MotionElement isNext={onboardingDirection === 'next'}>
              <ArtistStep />
            </MotionElement>
          )}
          {onboardingStep === OnboardingSteps.CHOOSE_TRACK && (
            <MotionElement isNext={onboardingDirection === 'next'}>
              <ChooseTrack />
            </MotionElement>
          )}
          {onboardingStep === OnboardingSteps.RELEASE_DETAILS && (
            <MotionElement isNext={onboardingDirection === 'next'}>
              <ReleaseDetailsPage />
            </MotionElement>
          )}
          {onboardingStep === OnboardingSteps.RELEASE_DATE && (
            <MotionElement isNext={onboardingDirection === 'next'}>
              <ReleaseDatePage isOnboarding />
            </MotionElement>
          )}
          {onboardingStep === OnboardingSteps.CREATE_ACCOUNT && (
            <MotionElement isNext={onboardingDirection === 'next'}>
              <CreateAccountPage formDetails={formMethods.getValues()} />
            </MotionElement>
          )}
        </MotionWrapper>
      </FormProvider>
    </div>
  );
};

export default OnboardingPage;
